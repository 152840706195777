@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Pragmatica';
  src: local('Pragmatica'),
    url('/public/fonts//Pragmatica_Extended-Black.otf') format('opentype');
}

body {
  margin: 0;
  font-family: Pragmatica, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #ffe974;
}

.btn_color {
  background-color: #ad0909;
}

code {
  font-family: Pragmatica, 'Courier New', monospace;
}
